// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
/*
export const environment = {
  production: false
};
*/

import { Environment } from './environment.model';

export class EnvironmentImpl extends Environment {
  public production = false;
  public readonly Mode = "Bigy";

  public readonly APIUrl = 'https://stgbigyapi.relationshop.net/V6/dxp/api'; // incomint v6
  public readonly WebUrl = 'https://stgbigy.relationshop.net/';
  public readonly EComCoreHost = 'https://stgbigy-api.relationshop.net';
	public readonly APIDistributionCore = '/distribution-core/v1.0/api';
  public readonly EndpointGatewayAPI = `${this.EComCoreHost}/bigygateway/v1.0/api`;

  // mi9 ecom
  public readonly EComHost = this.EComCoreHost + '/scrsecom/v2.0/api';
  public readonly EcomMi9v8Host = this.EComCoreHost + '/scecom/v1.0/api';
  public readonly PayGatewayUrl = 'https://stgbigym.relationshop.net/ecom';

  // dxp-ecom: online ordering: catering, giftcard, cake
  public readonly EComBagAPIURL = this.EComCoreHost + '/cart/v1.0/api';
  public readonly EComOrderAPIURL = this.EComCoreHost + '/order/v1.0/api';
  public readonly EComProductAPIURL = this.EComCoreHost + '/product/v2.0/api';
  public readonly EComStoreAPIURL = this.EComCoreHost + '/store/v1.0/api';
  public readonly SmartConnectPickupAPIURL = this.EComCoreHost + '/scpickup/v1.0/api';
  public readonly SCAdsAPIUrl = this.EComCoreHost + '/scads/v1.0/api';
  public readonly WeeklyAdAPIUrl = this.EComCoreHost + '/weeklyad/v1.0/api';
  public readonly CMSUrl = this.EComCoreHost + '/cms/v1.0/api';
  public readonly ScriptUrl = 'https://stgbigy-cloud.relationshop.net/dxp';
  public readonly DxpComponentBuildNumber = '70';

  public readonly VersionStoreAPI = '/v1.0';
  public readonly VersionOrderAPI = '/v1.0';
  public readonly VersionIdentityAPI = '/v1.0';

  public readonly ScanAndGoSvcId = 3;
  public readonly OnlineOrderSvcId = 4;
  public readonly TakeOutSvcId = 5;
  public readonly CateringSvcId = 20;
  public readonly PartyTraysSvcId = 21;
  public readonly GiftCardsSvcId = 19;
  public readonly CustomCakesSvcId = 11;
  public readonly MakeAListSvcId = 1;

  public IndentityAPI = 'https://stgbigyid.relationshop.net';

  public readonly ApplicationId = ''; // isAndroid() ? '56dd18ee-abe1-4dae-8737-bdf0d64898a7' = 'f3315060-5099-41df-809a-9c11e6e36d59';
  public readonly BlankImge = 'https://TestUnitedAdmin.relationshop.net/Images/1x1.gif';
  // public readonly DefaultImg = 'https://unitedadmin.relationshop.net/Admin/Images/CouponIcon/sr_coupon_icon.png';
  public readonly DefaultCompanyId = '3B4656BB-58A5-FC7D-2F06-B088FA805206';
  public readonly SaveToAndroidPay = 'https://androidpay.google.com/a/save';

  public readonly APIComsumerKey = '199e1034-48e4-4a69-af14-f125a03bd059';
  public readonly APIUserName = 'rsapiuser';
  public readonly APIPassword = 'rsef2019';

  public readonly UComApiKey = "";
  public readonly PaymentProvider = "WorldPay"
  public readonly HostedpagePaymentProvider = this.EComCoreHost + '/order/v1.0';
  /* matomo */
  public readonly EnableMatomo = true;
  public readonly MatomoCfg = {
    mUrl: 'https://dev-matomo.relationshop.net/mtm/',
    mId: 17,
    installedGoal: 1
  };
  public readonly FlyBuyAndroidToken = '171.Su5S1Csfq7iLn3MCp1RYgDvm';
  public readonly FlyBuyIosToken = '170.8HjjXjZcXfLwCFcVLWHVtwAx';
}

export const ENV: Environment = new EnvironmentImpl();

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
