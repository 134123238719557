import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { Geolocation } from '@ionic-native/geolocation/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
// import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { Device } from '@ionic-native/device/ngx';
import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';


// import { IonicGestureConfig } from "./gestures/ionic-gesture-config";

import { CacheModule } from "ionic-cache";

import { NgxPubSubModule } from '@pscoped/ngx-pub-sub';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AppLoadService } from './app-load.service';
import { AuthInterceptor } from './modules/gateway/auth.interceptor';
import { CacheInterceptor } from './modules/gateway/cache.interceptor';
// import { FirebaseMessaging } from '@ionic-native/firebase-messaging/ngx';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { Network } from '@ionic-native/network/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { MatomoModule } from 'ngx-matomo';
// import { Facebook } from '@ionic-native/facebook/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
// import { FlyBuy } from '@ionic-native/fly-buy/ngx';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { FirebaseDynamicLinks } from '@ionic-native/firebase-dynamic-links/ngx';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UserServiceModule } from '@rsApp/modules/account/provider/user.service';
import { StoreServiceModule } from '@rsApp/modules/store/providers/store.service';
import { OutDateModal } from './modules/auth/providers/isOutDate-guard.service';
import { CartListIconModule } from './modules/cart-list-icon/cart-list-icon.module';

export function getToken(appLoadService: AppLoadService) {
  return () => appLoadService.getToken();
}
const AppComponentTarget = AppComponent;
@NgModule({
  declarations: [AppComponentTarget, OutDateModal],
  imports: [
    BrowserModule,
    IonicModule.forRoot({ mode: 'ios', scrollAssist: true, scrollPadding: false }),
    IonicStorageModule.forRoot({
      name: '__rsbigydb',
      driverOrder: ['indexeddb', 'sqlite', 'websql']
      // driverOrder: ['websql']
    }),
    CommonModule,
    HttpClientModule,
    CacheModule.forRoot({ keyPrefix: 'rs-' }),
    AppRoutingModule,
    NgxPubSubModule,
    MatomoModule,
    UserServiceModule,
    StoreServiceModule,
    CartListIconModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }), 
  ],
  providers: [
    StatusBar,
    SplashScreen,
    InAppBrowser,
    Geolocation,
    OpenNativeSettings,
    // FirebaseAnalytics,
    // FirebaseMessaging,
    FirebaseX,
    Network,
    Device,
    // Facebook,
    Keyboard,
    // FlyBuy,
    FirebaseDynamicLinks,
    Diagnostic,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    // { provide: HAMMER_GESTURE_CONFIG, useClass: IonicGestureConfig },
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    AppLoadService,
    { provide: APP_INITIALIZER, useFactory: getToken, deps: [AppLoadService], multi: true },
    // { provide: SCPICKUP_API_URL, useValue: ENV.SmartConnectPickupAPIURL },
    // { provide: SCPICKUP_HTTP_CLIENT, useFactory: RelationshopEComCoreHttpClientFactory, deps: [HttpHandler, Credential, SCPICKUP_API_URL] }
  ],
  bootstrap: [AppComponentTarget],
  entryComponents:[OutDateModal]
})
export class AppModule { }
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
