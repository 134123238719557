import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService as AuthGuard } from '@rsApp/modules/auth/providers/auth-guard.service';
import { ForceChangePasswordGuardService as ForceChangePasswordGuard } from '@rsApp/modules/auth/providers/force-change-password-guard.service';
import { IsOutDateGuardService as IsOutDateGuard } from '@rsApp/modules/auth/providers/isOutDate-guard.service';
import { ShowLoginGuardService as ShowLoginGuard } from '@rsApp/modules/auth/providers/showlogin-guard.service';
import { StoreGuardService as StoreGuard } from '@rsApp/modules/auth/providers/store-guard.service';
import { CheckTutorial } from '../tutorial/providers/check-tutorial.service';
import { TabsPage } from './tabs.page';


const routes: Routes = [
  {
    path: 'tabs',
    component: TabsPage,
    children: [
      {
        path: 'home',
        children: [
          {
            path: '',
            loadChildren: () => import ('../home/home.module').then(x => x.HomeModule),
            canActivate: [ShowLoginGuard/*, FirstShowStoreGuardService*/, StoreGuard],
            canLoad:[CheckTutorial]
          }
        ]
      },
      // { path: 'receipt', loadChildren: '../receipt/receipt.module#ReceiptModule', canActivate: [AuthGuard], data: { requiredLogin: true } },
      { path: 'shopping-lists', 
        loadChildren: () => import ('../shopping-list/shopping-list.module').then(x => x.ShoppingListModule), 
        canActivate: [AuthGuard], 
        data: { requiredLogin: true}
      },
      { path: 'shopping-cart', 
        loadChildren: () => import ('../shopping-cart/shopping-cart.module').then(x => x.ShoppingCartModule), 
        canActivate: [AuthGuard], 
        data: { requiredLogin: true} 
      },
      { path: 'more', 
        loadChildren: () => import ('../more/more.module').then(x => x.MoreModule), 
        canActivate: [ForceChangePasswordGuard] 
      },
      { path: 'purchase',
        loadChildren: () => import ('../purchase/purchase-routing.module').then(x => x.PurchaseRoutingModule), 
        canActivate: [StoreGuard], 
        data: { requiredLogin: true } 
      },
      { path: 'recipe', 
        loadChildren: () => import ('../recipe/recipe.module').then(x => x.RecipeModule), 
        canActivate: [AuthGuard], 
        data: { requiredLogin: true } 
      },
      { path: 'punchcard',
        loadChildren: () => import ('../punchcard/punchcard.module').then(x => x.PunchcardModule),
        canActivate: [AuthGuard], 
        data: { requiredLogin: true } 
      },
      { path: 'products',
        loadChildren: () => import ('../product/product-routing.module').then(x => x.ProductRoutingModule),
        canActivate: [StoreGuard] 
      },
      { path: 'coupons',
        loadChildren: () => import ('../reward/reward-routing.module').then(x => x.RewardRoutingModule),
        canActivate: [AuthGuard], 
        data: { requiredLogin: true } 
      },
      { path: 'weekly-ad',
        loadChildren: () => import ('../weekly-ad/weekly-ad-routing.module').then(x => x.WeeklyAdRoutingModule),
        canActivate: [StoreGuard] 
      },
      // { path: 'welcome', loadChildren: '../welcome/welcome.module#WelcomeModule', canActivate: [GuestGuard] },
      // { path: 'dashboard', loadChildren: '../dashboard/dashboard-routing.module#DashboardRoutingModule', canActivate: [AuthGuard]},
      { path: 'deals',
        loadChildren: () => import ('../deals/deals.router.module').then(x => x.DealsPageRoutingModule),
        canActivate: [AuthGuard], 
        data: { requiredLogin: true } 
      },
      // { path: 'reward', loadChildren: '../reward/reward-routing.module#RewardRoutingModule', canActivate: [AuthGuard], data: { requiredLogin: true } },
      { path: 'rewards', 
        loadChildren: () => import ('../dxp-rewards/dxp-rewards-routing.module').then(x => x.DXPRewardRoutingModule),
        canActivate: [AuthGuard], 
        data: { requiredLogin: true } 
      },
      { path: 'mybigy',
        loadChildren: () => import ('../myBigy/myBigy.module').then(x => x.MyBigyModule),
        canActivate: [AuthGuard], 
        data: { requiredLogin: true }
      },
      { path: 'gas',
        loadChildren: () => import ('../gas/gas.module').then(x => x.GasModule),
        canActivate: [StoreGuard], 
        data: { requiredLogin: true } 
      },
    ],
    canActivate: [ForceChangePasswordGuard, IsOutDateGuard]
  },
  {
    path: '',
    redirectTo: '/tabs/home',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule],
  declarations: []
})
export class TabsPageRoutingModule { }
