import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AlertController, NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { User } from '@rsApp/modules/account/provider/user.service';
import { Utils } from '@rsApp/modules/utils/providers/utils';
import { CacheService } from 'ionic-cache';
import { combineLatest, of, Subject, throwError } from 'rxjs';
import { catchError, concatMap, take, takeUntil, filter, switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Credential } from './credential.service';
@Injectable({
	providedIn: 'root'
})
export class CheckVerifyEmailService {
	backUrl: string;
	private _destroy$: Subject<boolean> = new Subject();
	isShow: boolean = false;
	isAddPoint: boolean;
	constructor(
		public authService: AuthService,
		public router: Router,
		public utils: Utils,
		public alertCtrl: AlertController,
		public user: User,
		public cache: CacheService,
		public navCtrl: NavController,
		public cre: Credential,
		public storage: Storage
	) {
	}
	async init() {
		if (!this._destroy$) this._destroy$ = new Subject()

		const user$ = this.authService.getCurrentUser().pipe(takeUntil(this._destroy$));
		const routerEvents$ = this.router.events.pipe(takeUntil(this._destroy$), filter(event => event instanceof NavigationEnd));
	
		if (!user$) return;
	
		combineLatest([user$, routerEvents$])
			.pipe(
				switchMap(([user, event]) => {
					if (!user || !(event instanceof NavigationEnd)) return of(null);
					if (!this.isShow && !["/otp-email", "/signup", "/login", "/free-products", "/fuel-savings", "/grocery-savings"].some(path => event.url.includes(path))) {
						this.checkShowPopup(user);
					}
					return of(null);
				}),
				takeUntil(this._destroy$)
			).subscribe();
	}
	
	onDestroy() {
		if (this._destroy$) {
			this._destroy$.next(true);
			this._destroy$.complete();
			this._destroy$ = null;
		}
	}
	
	checkShowPopup(user) {
		if (user && !user.IsVerifiedEmail) {
			if (user.IsForceVerifyEmail) {
				this.alertVerifyEmail(user);
			} else {
				this.checkMarkVerifyEmail(user);
			}
		}
	}
	
	checkMarkVerifyEmail(user) {
		this.user.markVerifyEmail(user.UserID).subscribe((resp: any) => {
			if (resp) {
				this.alertVerifyEmail(user);
				user.IsForceVerifyEmail = true;
				this.cre.setCurrentUser(user)
			}
		});
	}
	
	async alertVerifyEmail(user) {
		this.isShow = true;
		let isSendClicked = false;
		const alert = await this.alertCtrl.create({
			header: 'Please Verify Your Email Address',
			message:
				'<p class="email">' + user.UserEmail + '</p>' +
				'<p class="text-above">We are now including email verification as part of our Rewards enrollment. ' +
				'You will only be asked to do this once.</p>' +
				'<p class="text-after">After you click the button below you will receive an ' +
				'email with a unique code you can enter on the next page.</p>' +
				'<p class="text-final">Thanks for your help!</p>',
			cssClass: 'cus-alert-modal verify-email-popup alert-custom js-platform-popup',
			buttons: [
				{
					text: 'SEND',
					handler: () => {
						this.sendVerifyEmail(user);
						isSendClicked = true;
					},
					cssClass: 'btn-send'
				},
				{
					text: '',
					cssClass: 'btn-close-alert'
				}
			]
		});
		await alert.present();
		await this.utils.hideLoading();
		alert.onDidDismiss().then(()=> {
			if (!isSendClicked) {
				const customEvent = new CustomEvent('showPopUpVerifyEmail');
				window.dispatchEvent(customEvent);
			}
			this.isShow = false;
		});
	}

	async sendVerifyEmail(user) {
		await this.utils.showLoading({ duration: 40000 });
		this.user.getPointAvailable()
			.pipe(
				take(1),
				concatMap((response: boolean) => {
					this.isAddPoint = response;
					return this.user.sendOtpEmail(user.UserEmail, this.isAddPoint);
				}),
				catchError((error) => {
					this.utils.alertError('Something went wrong');
					return throwError(() => error);
				}),
			)
			.subscribe({
				next: (resp: any) => {
					if (resp) {
						this.router.navigate(['/account/otp-email']);
					}
				},
			});
	}
}