import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService as AuthGuard } from './modules/auth/providers/auth-guard.service';
import { ForceChangePasswordGuardService as ForceChangePasswordGuard } from './modules/auth/providers/force-change-password-guard.service';
import { GuestGuardService as GuestGuard } from './modules/auth/providers/guest-guard.service';
import { StoreGuardService as StoreGuard } from './modules/auth/providers/store-guard.service';
import { TabsPageModule } from './modules/tabs/tabs.module';

const routes: Routes = [
  {
    path: '', redirectTo: 'home', pathMatch: 'full',
    canActivate: [AuthGuard, StoreGuard],
    data: { requiredLogin: true },
  },
  {
    path: 'home', redirectTo: '/tabs/home', pathMatch: 'full',
    canActivate: [AuthGuard, StoreGuard],
    data: { requiredLogin: true },
  },
  {
    path: 'signup', 
    loadChildren: () => import ('./modules/bigy-signup/bigy-signup.routing.module').then (x => x.BigySignupRoutingModule),
    canActivate: [GuestGuard]
  },
  {
    path: 'login', 
    loadChildren: () => import ('./modules/sign-in/sign-in.module').then(x => x.SignInModule),
    canActivate: [GuestGuard]
  },
  {
    path: 'store', 
    loadChildren: () => import ('./modules/store/store.module').then(x => x.StoreModule),
    canActivate: [ForceChangePasswordGuard]

  },
  { path: 'tutorial', 
    loadChildren: () => import ('./modules/tutorial/tutorial.module').then(x => x.TutorialModule) 
  },
  {
    path: 'checkout', 
    loadChildren: () => import ('./modules/checkout/checkout.module').then(x => x.CheckoutModule),
    canActivate: [AuthGuard, ForceChangePasswordGuard],
    data: { requiredLogin: true }
  },
  {
    path: 'account', 
    loadChildren: () => import ('./modules/account/account.module').then(x => x.AccountModule),
    canActivate: [AuthGuard],
    data: { requiredLogin: true }
  },
  {
    path: 'myGas', 
    loadChildren: () => import ('./modules/account/account.module').then(x => x.AccountModule),
    canActivate: [AuthGuard],
    data: { requiredLogin: true }
  },
  { path: 'offer', 
    loadChildren: () => import ('./modules/deals/deals.router.module').then(x => x.DealsPageRoutingModule) 
  },
  {
    path: 'online-order',
    loadChildren: () => import ('./modules/cus-online-order/cus-online-order-routing.module').then(x => x.CusOnlineOrderRoutingModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'cake-catering/:systemServiceId',
    loadChildren: () => import ('./modules/cus-online-order/cus-online-order-routing.module').then(x => x.CusOnlineOrderRoutingModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'commerce/:urlSlugSystemService',
    loadChildren: () => import ('./modules/cus-online-order/commerce-routing.module').then(x => x.CommerceRoutingModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'notification',
    loadChildren: () => import ('./modules/notification/notification.module').then(x => x.NotificationModule),
    canActivate: [AuthGuard],
    data: { requiredLogin: true }
  },
  // {
  //   path: 'my-bigy', loadChildren: './modules/myBigy/myBigy.module#MyBigyModule',
  //   canActivate: [AuthGuard],
  //   data: { requiredLogin: true }
  // },
  // { path: 'pickup', loadChildren: './modules/pickup/pickup.module#PickupModule'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    TabsPageModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
