import {Injectable} from '@angular/core';
import {HttpClient, HttpRequest, HttpHeaders, HttpParams, HttpHandler } from '@angular/common/http';

import {Observable} from 'rxjs';

import { ENV } from '@app/env';
import { Credential } from '@rsApp/modules/auth/providers/credential.service';

export type HttpObserve = 'body' | 'events' | 'response';


@Injectable({
  providedIn: 'root'
})
export class RelationshopHttpClientBigYGateWay extends HttpClient{
	protected api = ENV.EndpointGatewayAPI;
    constructor(handler: HttpHandler, private cre: Credential) {
       super(handler);
    }
	request(first: string|HttpRequest<any>, url?: string, options: {
    body?: any,
    headers?: HttpHeaders|{[header: string]: string | string[]},
    observe?: HttpObserve,
    params?: HttpParams|{[param: string]: string | string[]},
    reportProgress?: boolean,
    responseType?: 'arraybuffer'|'blob'|'json'|'text',
    withCredentials?: boolean,
  } = {}): Observable<any> {

	let override = {};
	if(!options){
		options = {};
	}
    url = this.api+url;

    // token
	let headers: HttpHeaders|undefined = undefined;
    if (options.headers instanceof HttpHeaders) {
        headers = options.headers;
    } else {
        headers = new HttpHeaders(options.headers);
    }
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.cre.rsApiToken || '');
    headers = headers.set('X-MCMAccessToken', this.cre.rsApiToken || '');
    headers = headers.set('TrackingCode', ENV.TrackingCode || '');
    options.headers = headers;

    let req = super.request(first as string, url, options as any);
    return req;
  }  
}